import React from 'react';
import './styles.scss';

export const AboutContent = ({content}) => {
    return (
        <content>
            <div dangerouslySetInnerHTML={{__html: content}} />
        </content>
    );
};
