import React from 'react';
import Helmet from 'react-helmet';

export const Head = ({ title, description }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-162933668-1"></script>
            <script>{`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', 'UA-162933668-1');
                `}
            </script>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
            <meta name="title" content={title} />>
            <meta name="description" content={description} />
            <meta property="og:image:width" content="279" />
            <meta property="og:image:height" content="279" />
            <meta property="og:description" content="Artwork by artist Katya Bessmertnaya" />
            <meta property="og:title" content="Graphics and Paintings by Katya Bessmertnaya" />
            <meta property="og:url" content="https://katya.graphics" />
            <meta property="og:image" content="/img/og-image.jpg" />
            <meta property="twitter:url" content="https://katya.graphics" />
            <meta property="twitter:title" content="Artwork by artist Katya Bessmertnaya" />
            <meta property="twitter:description" content="Graphics and Paintings by Katya Bessmertnaya" />
            <meta property="twitter:image" content="/img/og-image.jpg" />
            <link rel="apple-touch-icon-precomposed" sizes="57x57" href="/img/apple-touch-icon-57x57.png" />
            <link rel="apple-touch-icon-precomposed" sizes="114x114" href="/img/apple-touch-icon-114x114.png" />
            <link rel="apple-touch-icon-precomposed" sizes="72x72" href="/img/apple-touch-icon-72x72.png" />
            <link rel="apple-touch-icon-precomposed" sizes="144x144" href="/img/apple-touch-icon-144x144.png" />
            <link rel="apple-touch-icon-precomposed" sizes="60x60" href="/img/apple-touch-icon-60x60.png" />
            <link rel="apple-touch-icon-precomposed" sizes="120x120" href="/img/apple-touch-icon-120x120.png" />
            <link rel="apple-touch-icon-precomposed" sizes="76x76" href="/img/apple-touch-icon-76x76.png" />
            <link rel="apple-touch-icon-precomposed" sizes="152x152" href="/img/apple-touch-icon-152x152.png" />
            <link rel="icon" type="image/png" href="/img/favicon-196x196.png" sizes="196x196" />
            <link rel="icon" type="image/png" href="/img/favicon-96x96.png" sizes="96x96" />
            <link rel="icon" type="image/png" href="/img/favicon-32x32.png" sizes="32x32" />
            <link rel="icon" type="image/png" href="/img/favicon-16x16.png" sizes="16x16" />
            <link rel="icon" type="image/png" href="/img/favicon-128.png" sizes="128x128" />
            <meta name="application-name" content="Katya Bessmertnaya"/>
            <meta name="msapplication-TileColor" content="#FFFFFF" />
            <meta name="msapplication-TileImage" content="/img/mstile-144x144.png" />
            <meta name="msapplication-square70x70logo" content="/img/mstile-70x70.png" />
            <meta name="msapplication-square150x150logo" content="/img/mstile-150x150.png" />
            <link href="https://fonts.googleapis.com/css?family=Barlow+Condensed&display=swap" rel="stylesheet" />
        </Helmet>
    );
};
