import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { inject, observer } from 'mobx-react';

import { Layout } from '../components/Layout/Layout';
import { Head } from '../components/Head/Head';
import { AboutContent } from '../components/AboutContent/AboutContent';

export const AboutPageTemplate = ({ content }) => {
    return (
        <main className="about-page">
            {content}
        </main>
    )
}

AboutPageTemplate.propTypes = {
    content: PropTypes.string
}

const AboutPage = inject('store')(observer(({ data, store }) => {
    const { markdownRemark: post } = data;
    store.setPage('about');

    return (
        <Layout>
            <Head title="About Katya Bessmertnaya" description="Paintings and Graphics by Katya Bessmertnaya" />
            <main className="about-page">
                <AboutContent content={post.html} />
            </main>                
        </Layout>
    )
}));

AboutPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
    }
  }
`
